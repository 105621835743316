<template>
    <div>
        <AdminTop
            heading="Lärare"
            add-new-url="/admin/teachers/new"
        />

        <BaseTable :show-delete="true">
            <TableHeader>
                <TableHeaderCell>
                    Namn
                </TableHeaderCell>

                <TableHeaderCell>
                    E-post
                </TableHeaderCell>

                <TableHeaderCell>
                    Telefon
                </TableHeaderCell>
            </TableHeader>

            <TableBody>
                <TableRow
                    v-for="(teacher, index) in teachers"
                    :key="teacher.id"
                    :to="`/admin/teachers/${teacher.id}`"
                    @delete="removeTeacher(teacher.id, index)"
                >
                    <TableCell>
                        <div class="flex items-center">
                            <Avatar
                                class="mr-6"
                                :name="teacher.name"
                                :image="teacher.image"
                            />

                            <p class="text-gray-900 whitespace-no-wrap">
                                {{ teacher.name }}
                            </p>
                        </div>
                    </TableCell>

                    <TableCell>
                        <p class="text-gray-900 whitespace-no-wrap">
                            {{ teacher.email }}
                        </p>
                    </TableCell>

                    <TableCell>
                        <p class="text-gray-900 whitespace-no-wrap">
                            {{ teacher.phone }}
                        </p>
                    </TableCell>
                </TableRow>
            </TableBody>
        </BaseTable>
    </div>
</template>

<script>
import AdminTop from '@/components/ui/AdminTop';
import BaseTable from '@/components/table/BaseTable';
import TableHeader from '@/components/table/TableHeader';
import TableHeaderCell from '@/components/table/TableHeaderCell';
import TableBody from '@/components/table/TableBody';
import TableRow from '@/components/table/TableRow';
import TableCell from '@/components/table/TableCell';
import TeacherService from '@/services/admin/TeacherService';
import Avatar from '@/components/ui/Avatar';
import { confirmRemove } from '@/utils/utils.js';

export default {
    components: {
        AdminTop,
        BaseTable,
        TableHeader,
        TableHeaderCell,
        TableBody,
        TableRow,
        TableCell,
        Avatar
    },

    data: () => ({
        teachers: []
    }),

    created () {
        this.getTeachers();
    },

    methods: {
        async getTeachers () {
            const res = await TeacherService.all();

            this.teachers = res.data;
        },

        async removeTeacher (id, index) {
            if (!confirmRemove()) {
                return;
            }

            const res = await TeacherService.remove(id);

            if (res && res.status === 200) {
                const teachers = [...this.teachers];

                teachers.splice(index, 1);

                this.teachers = teachers;
            }
        }
    }
};
</script>

<style lang="postcss" scoped>
.avatar {
    width: 4.5rem;
    height: 4.5rem;
}

.avatar >>> .initials {
    font-size: 1.4rem;
}
</style>
